@media (max-width: $screen-lg) {
    // lg-md view
    .about { 
        .about-holder { 
            .text { 
                width: calc(100% - 227px);
                padding: 39px 10px 26px 0px;
                .text-holder { 

                    h2, .h2 {
                        margin: 0 0 11px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 141%;
                        margin: 0 0 11px;
                    }
                }
            }

            .image { 
                width: 227px;
                height: 140px;
                padding: 87px 0 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
