@media (max-width: $screen-lg) {
    // lg-md view
    .brands {
        padding: 0 0 54px;
        .brands-holder { 

            .heading { 
                margin: 0 0 10px;
            }

            .brands-sldier { 


                .brands-slider-holder { 
                    .brand { 
                        a {
                            width: 200px;
                            height: 150px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
