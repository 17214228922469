@media (max-width: $screen-lg) {
    // lg-md view
    .services { 
        .services-holder {
            .heading { 
                margin: 0 0 29px;
            }

            .services-list-holder { 
                justify-content: center;
                width: calc(100% + 9px);
                margin-left: -4.5px;
                .service { 
                    width: 50%;
                    padding: 0 4.5px;
                    .service-holder { 
                        .text {
                            font-size: 14px;
                            padding: 10px 20px 7px 0px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
