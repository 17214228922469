.services { 
    display: inline-block;
    width: 100%;
    background-image: url(../img/services-bkg.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    margin: 0 0 -3px;
    .services-holder { 
        display: inline-block;
        width: 100%;
        padding: 60px 0 52px;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 21px;
            h2, .h2 { 
                color: white;
            }
        }

        .services-list-holder { 
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 10px);
            margin-left: -5px;
            .service { 
                width: 33.333333%;
                height: auto;
                box-sizing: border-box;
                padding: 0 5px;
                margin: 0 0 10px;
                .service-holder { 
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: $brand-secondary;
                    border-radius: 5px;
                    color: white;
                    .icon {
                        width: 120px;
                        height: 120px;
                        float: left;
                        svg {}
                    }

                    .text { 
                        width: calc(100% - 120px);
                        float: left;
                        box-sizing: border-box;
                        padding: 10px 20px 10px 1px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        color: white;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/services";
@import "../../media/mobile/includes/index/services";
