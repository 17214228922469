.contacts-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 15px;
	.contacts-block-holder { 
        display: inline-block;
        width: 100%;
        padding: 100px 0 125px;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 23px;
            h2, .h2 { 

            }
        }

        .contacts-infoes { 
            display: inline-block;
            width: 100%;
            margin: 0 0 16px;
            .contact-info { 
                float: left;
                margin-right: 31px;
                &:last-of-type {
                    margin: 0;
                }
                a, .dummy { 
                    display: inline-block;
                    &:hover {
                        color: $brand-primary;
                    }
                    .icon { 
                        float: left;
                        margin-right: 10px;
                        svg { 
                            margin: -3px 0 0;
                        }
                    }

                    .text { 
                        float: left;
                    }   
                }
                .dummy {
                    &:hover {
                        color: $gray-base;
                    }
                }
            }
        }

        .map { 
            display: inline-block;
            width: 100%;
            height: 512px;
            .map-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                >img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/contacts";
@import "../../media/mobile/includes/index/contacts";
