.brands { 
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding: 0 0 91px;
	.brands-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 31px;
            h2, .h2 { 
                
            }
        }

        .brands-sldier { 
            display: inline-block;
            width: 100%;

            .slick-slider {
                display: inline-block;
                width: calc(100% + 10px);
                margin-left: -5px;
                .slick-list {
                    overflow: visible;
                    .slick-track {
                        .slick-slide {
                            padding: 0 5px;
                        }
                    }
                }
            }

            .brands-slider-holder { 

                .brand { 
                    a { 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 220px;
                        height: 150px;
                        box-sizing: border-box;
                        border: 1px solid #E8E8E8;
                        border-radius: 5px;
                        background: white;
                        &:hover {
                            border: 1px solid $brand-primary;
                            transition: all 300ms;
                            img {
                                opacity: 0.86;
                                transition: all 300ms;
                            }
                        }
                        img { 
                            max-width: calc(100% - 20px);
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/brands";
@import "../../media/mobile/includes/index/brands";
