@media (max-width: $screen-lg) {
    // lg-md view
    .company-points {
        padding: 60px 0 40px;
        .comapny-points-holder { 
            >.heading { 
                margin: 0 0 20px;
                .note {
                    line-height: 140%;
                    margin: 10px 0 0;
                }
            }

            .points {
                width: calc(100% + 20px);
                margin-left: -10px;
                .point { 
                    padding: 0 10px;
                    margin:  0 0 20px;
                    .point-holder { 
                        padding: 9px;
                        .heading {
                            .text {
                                h3, .h3 {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }

                        .desc {
                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 10px;
                                line-height: 140%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
