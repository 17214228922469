@media (max-width: $screen-sm) {
    // sm-view
    .brands {
        padding: 0 0 34px;
        .brands-holder { 

            .heading { 
              
            }

            .brands-sldier { 


                .brands-slider-holder { 
                    .brand { 
                        a {
                            width: 113px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
