.company-points { 
    display: inline-block;
    width: 100%;
    padding: 100px 0 71px;
	.comapny-points-holder { 
        display: inline-block;
        width: 100%;
        >.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 34px;
            h2, .h2 { 

            }

            .note { 
                margin: 13px 0 0;
                font-weight: 300;
            }
        }

        .points { 
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
            .point { 
                width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 0 15px;
                margin:  0 0 30px;
                &.point-50 {
                    width: 50%;
                }
                &.point-33 {
                    width: 33.333333%;
                }
                .point-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #E8E8E8;
                    border-radius: 5px;
                    background: white;
                    box-sizing: border-box;
                    padding: 15px;
                    .heading { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 7px;
                        .icon { 
                            float: left;
                            width: 25px;
                            height: 25px;
                            svg {
                            }
                        }

                        .text { 
                            float: left;
                            width: calc(100% - 25px);
                            box-sizing: border-box;
                            padding-left: 10px;
                            h3, .h3 { 
                                font-style: normal;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 25px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .desc { 
                        display: inline-block;
                        width: 100%;
                        p { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 140%;
                            margin: 0 0 7px;
                            &:last-of-type {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/company_points";
@import "../../media/mobile/includes/index/company_points";
