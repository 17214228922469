.make-request { 
    display: inline-block;
    width: 100%;
    color: white;
    background: $brand-secondary;
	.make-request-holder { 
        display: inline-block;
        width: 100%;
        padding: 77px 0 72px;
        .heading { 
            display: inline-block;
            width: 100%;
            text-align: center;
            margin: 0 0 34px;
            h2, .h2 { 

            }

            .note { 
                display: inline-block;
                width: 100%;
                margin: 13px 0 0;
            }

        }

        .form { 
            display: inline-block;
            width: 100%;
            form { 

                .form-group { 

                    .form-col { 

                        .form-field { 
                            justify-content: center;
                            label.input { 

                                input { 
                                    padding: 0px 10px 0 10px;
                                }
                            }

                            label.checkcontainer {
                                width: 330px;
                                margin: -6px 0 -7px;
                                .name {
                                    width: calc(100% - 16px - 10px);
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }

                .action { 

                    .submit { 
                        text-align: center;
                        .btn { 
                            width: 374px;
                            padding: 19px 0;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/make_request";
@import "../../media/mobile/includes/index/make_request";
