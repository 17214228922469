.main-block { 
    display: inline-block;
    width: 100%;
    height: 607px;
    position: relative;
	.image { 
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
		img { 
            width: 100%;
            height: 100%;
            object-fit: cover;
		}
	}

	.main-block-contents { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
		.main-block-contents-holder { 
            display: inline-block;
            width: 100%;
            .text { 
                float: left;
                width: calc(100% - 389px - 67px);
                padding: 181px 0 0;
                .text-holder { 
                    display: inline-block;
                    width: 100%;
                    color: white;
                    h1, .h1 { 
                        margin: 0 0 10px;
                    }

                    p { 

                    }

                    p.thin { 
                        font-weight: 300;
                    }
                }
            }

            .form { 
                width: 389px;
                float: right;
                float: right;
                padding: 141px 0 0;
                .form-holder { 
                    display: inline-block;
                    width: 100%;
                    background: rgba(255, 255, 255, 0.2);
                    border: 1px solid #FFFFFF;
                    backdrop-filter: blur(5px);
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding:  20px 29.5px;
                    form { 
                        color: white;
                        .heading { 
                            display: inline-block;
                            width: calc(100% + 20px);
                            margin-left: -9.5px;
                            margin-bottom: 14px;
                            h3, .h3 { 
                                font-style: normal;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 33px;
                            }
                        }

                        .form-group { 

                            .form-col { 

                                .form-field { 
                                    margin-bottom: 8px;
                                    label.input { 

                                        .name { 

                                        }

                                        input { 
                                            background: rgba(255, 255, 255, 0.15);
                                            color: white;
                                            @include placeholder {
                                                color: #CACACA;
                                            }
                                        }
                                    }

                                    label.checkcontainer {
                                        margin: 6px 0 4px;
                                        .name {
                                            width: calc(100% - 16px - 8px);
                                            margin-left: 8px;
                                        }
                                    }
                                }
                            }
                        }

                        .action { 

                            .submit { 

                                .btn { 

                                }
                            }
                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/index/main_block";
@import "../../media/mobile/includes/index/main_block";
