@media (max-width: $screen-sm) {
    // sm-view
    .services { 
        .services-holder {
            padding: 40px 0 36px;
            .heading { 
                margin: 0 0 10px;
            }

            .services-list-holder { 
                width: 100%;
                margin-left: 0;
                .service { 
                    width: 100%;
                    padding: 0;
                    margin: 0 0 5px;
                    .service-holder { 
                        .icon {
                            width: 50px;
                            height: 50px;
                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .text {
                            width: calc(100% - 50px);
                            font-size: 12px;
                            padding: 0 12px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
