@media (max-width: $screen-lg) {
    // lg-md view
    .contacts-block {
        .contacts-block-holder { 
            padding: 61px 0 63px;
            .heading {
                margin: 0 0 15px;
            }

            .contacts-infoes { 
                margin: 0 0 4px;
                .contact-info { 
                    a, .dummy { 
                        font-size: 14px;
                    }
                }
            }

            .map {
                height: 40vw;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
