.about { 
    display: inline-block;
    width: 100%;
    background-color: $brand-secondary;
    background-image: url(../img/about-bkg.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    color: white;
	.about-holder { 
        display: inline-block;
        width: 100%;
        .text { 
            float: left;
            width: calc(100% - 474px);
            box-sizing: border-box;
            padding: 60px 30px 47px 2px;
            .text-holder { 
                display: inline-block;
                width: 100%;
                h2, .h2 { 
                    margin: 0 0 20px;
                }

                p { 
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    margin: 0 0 9px;
                }
            }
        }

        .image { 
            float: left;
            width: 474px;
            height: 313px;
            padding: 60px 0 0;
            .image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.25));
                border-radius: 5px;
                img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/about";
@import "../../media/mobile/includes/index/about";
