@media (max-width: $screen-lg) {
    // lg-md view
    .reviews { 
        .reviews-holder { 
            padding: 60px 0 58px;
            .heading {
                margin: 0 0 10px;
            }

            .reviews-list { 
                .review { 
                    margin: 0 0 11px;
                    .review-holder { 
                        .review-body { 
                            .review-body-holder { 
                                .base-info { 
                                    .author { 
                                        .name {
                                            font-size: 16px;
                                        }
                                    }
                                }

                                .review-text { 
                                    p {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pagination { 
                margin: 22px 0 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
