@media (max-width: $screen-sm) {
    // sm-view
    .reviews { 
        .reviews-holder { 
            padding: 40px 0 35px;
            .heading {
                
            }

            .reviews-list { 
                .review { 
                    margin: 0 0 6px;
                    .review-holder { 
                        align-items: flex-start;
                        padding: 10px;
                        .image { 
                            width: 30px;
                            height: 30px;
                        }

                        .review-body { 
                            width: calc(100% - 30px);
                            padding: 0 0 0 15px;
                            .review-body-holder { 
                                .base-info { 
                                    .author { 
                                        .name {
                                            font-size: 12px;
                                            line-height: 16px;
                                        }
                                    }

                                    .review-mark { 
                                        .star {
                                            margin-left: 0px;
                                            svg {
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }
                                    }
                                }

                                .review-text { 
                                    p {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pagination { 
                margin: 20px 0 0;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
