@media (max-width: $screen-sm) {
    // sm-view
    .company-points {
        padding: 41px 0 40px;
        .comapny-points-holder { 
            .points {
                width: 100%;
                margin: 0;
                .point {
                    padding: 0;
                    margin: 0 0 9px;
                    &.point-50 {
                        width: 100%;
                    }
                    &.point-33 {
                        width: 100%;
                    }
                    .point-holder { 
                        .heading { 
                            margin: 0 0 4px;
                            .icon { 
                                width: 15px;
                                height: 15px;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .text { 
                                width: calc(100% - 15px);
                                padding-left: 5px;
                                h3, .h3 { 
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                        }

                        .desc { 
                            p {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
