.reviews { 
    display: inline-block;
    width: 100%;
	.reviews-holder { 
        display: inline-block;
        width: 100%;
        padding: 100px 0 84px;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            h2, .h2 { 

            }
        }

        .reviews-list { 
            display: inline-block;
            width: 100%;
            .review { 
                display: inline-block;
                width: 100%;
                margin: 0 0 27px;
                &:last-of-type {
                    margin: 0;
                }
                .review-holder { 
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background: #F8F8F8;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 0 20px;
                    .image { 
                        float: left;
                        width: 90px;
                        height: 90px;
                        .iamge-holder { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            padding: 0 0 0;
                            img { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                        }
                    }

                    .review-body { 
                        float: left;
                        width: calc(100% - 90px);
                        box-sizing: border-box;
                        padding: 20px 0 20px 15px;
                        .review-body-holder { 
                            display: inline-block;
                            width: 100%;
                            .base-info { 
                                display: inline-block;
                                width: 100%;
                                margin:  0 0 3px;
                                .author { 
                                    float: left;
                                    .name { 
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 18px;
                                        line-height: 25px;
                                    }
                                }

                                .review-mark { 
                                    float: left;
                                    margin-left: 5px;
                                    &.mark-5 {
                                        .star {
                                            color: #FFD953;
                                        }
                                    }
                                    &.mark-4 {
                                        .star {
                                            color: #FFD953;
                                        }
                                        .star:nth-of-type(5) {
                                            color: #E8E8E8;
                                        }
                                    }
                                    &.mark-3 {
                                        .star {
                                            color: #FFD953;
                                        }
                                        .star:nth-of-type(5) {
                                            color: #E8E8E8;
                                        }
                                        .star:nth-of-type(4) {
                                            color: #E8E8E8;
                                        }
                                    }
                                    &.mark-2 {
                                        .star:nth-of-type(1) {
                                            color: #FFD953;
                                        }
                                        .star:nth-of-type(2) {
                                            color: #FFD953;
                                        }
                                    }
                                    &.mark-1 {
                                        .star:nth-of-type(1) {
                                            color: #FFD953;
                                        }
                                    }
                                    .star { 
                                        float: left;
                                        color: #E8E8E8;
                                        margin-left: 4px;
                                        svg { 

                                            path { 

                                            }
                                        }
                                    }
                                }
                            }

                            .review-text { 
                                display: inline-block;
                                width: 100%;
                                p { 
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 140%;
                                    margin: 0 0 8px;
                                    &:last-of-type {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .pagination { 
            float: right;
            margin: 26px 0 0;
            nav { 

                ul { 
                    margin: 0;
                    padding: 0;
                    li { 
                        float: left;
                        margin-left: 5px;
                        a { 
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            background: #F8F8F8;
                            border-radius: 5px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: $brand-secondary;
                            &:hover {
                                background: #E1E1E1;
                            }
                            &.current {
                                background: #E1E1E1;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/reviews";
@import "../../media/mobile/includes/index/reviews";
