@media (max-width: $screen-sm) {
    // sm-view
    .about { 
        .about-holder { 
            padding: 21px 0 19px;
            .text { 
                width: 100%;
                padding: 0;
                margin:  0 0 8px;
                .text-holder { 

                    h2, .h2 {
                        margin: 0 0 11px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 141%;
                        margin: 0 0 11px;
                    }
                }
            }

            .image { 
                width: 100%;
                height: 55vw;
                padding: 0;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
