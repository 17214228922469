@media (max-width: $screen-sm) {
    // sm-view
    .contacts-block {
        .contacts-block-holder { 
            padding: 40px 0 20px;

            .heading {
                margin: 0 0 10px;
            }

            .contacts-infoes { 
                margin: 0 0 4px;
                display: flex;
                flex-wrap: wrap;
                .contact-info { 
                    margin-right: 10px;
                    margin-bottom: 5px;
                    &:first-of-type {
                        margin-right: 0;
                    }
                    a, .dummy { 
                        display: flex;
                        font-size: 12px;
                        
                        align-items: center;
                        .icon {
                            margin-right: 5px;
                            svg {
                                margin: 0;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            .map {
                height: 72vw;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
