@media (max-width: $screen-lg) {
    // lg-md view
    .make-request { 
        .make-request-holder { 
            padding: 42px 0 40px;
            .heading {
                margin: 0 0 24px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
