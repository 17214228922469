@media (max-width: $screen-sm) {
    // sm-view
    .main-block { 
        height: 450px;

        .main-block-contents { 
            .main-block-contents-holder { 
                .text { 
                    padding: 75px 0 0;
                    .text-holder { 
                        h1, .h1 {
                            margin: 0 0 11px;
                        }

                        .action {
                            margin: 29px 0 0;
                            .btn {
                                width: 100%;
                                padding: 9px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
