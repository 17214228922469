@media (max-width: $screen-sm) {
    // sm-view
    .make-request { 
        .make-request-holder { 
            padding: 22px 0 18px;
            .heading {
                margin: 0 0 19px;
                .note {
                    line-height: 140%;
                }
            }

            .form { 
                form { 

                    .form-group { 
    
                        .form-col { 
    
                            .form-field { 

                                label.checkcontainer {
                                    width: 100%;
                                    margin: 11px 0 8px;
                                }
                            }
                        }
                    }

                    .action { 
                        .submit { 
                            .btn {
                                width: 100%;
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
